import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUserAttributes } from "aws-amplify/auth";

const initialState = {
    user: {
        email: '',
    },
    status: 'idle',
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    extraReducers(builder) {
        builder
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            .addCase(fetchAuthenticatedUser.fulfilled, (state, action) => {
                state.user = action.payload
            })
    },
})

export const fetchAuthenticatedUser = createAsyncThunk('authentication/fetchAuthenticatedUser', async () => {
    try {
        const response = await fetchUserAttributes();
        return response
    } catch (error) {
        console.log('userAttributes error: ', error);
    }
})

export const { setAuthenticatedUser } = authenticationSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.session.value)`
export const getAuthenticatedUser = (state) => { return state.value }

export default authenticationSlice.reducer