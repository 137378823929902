import { lazy } from "react";
import { Link, createBrowserRouter } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const AuditLogs = lazy(() => import("../views/AuditLogs.js"));

/*****Routes******/
///https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/#creating-protected-routes TIM TODO

const router = () => createBrowserRouter(
  [
    {
      path: "/",
      element: <FullLayout />,
      children: [
        //{ path: "/", title: "Recordings", exact: true, element: <Recordings /> },
        { path: "/", title: "AuditLogs", exact: true, element: <AuditLogs /> },

        //{ path: "/:id", title: "Recording - :id", exact: true, element: <ViewRecording />}
      ],
    },
    {
      path: "*",
      element: <NoMatch />,
    },
  ]
);

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default router;
