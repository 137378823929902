import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { client } from "../../api/client"

const apiURL = process.env.REACT_APP_GENESYSENTITIES_API_URL

const initialState = {
  users: [],
  qs: [],
  serviceMappings: [],
  entities: [],
  status: 'idle',
  error: null,
}

export const fetchEntities = createAsyncThunk('filter/fetchEntities', async (entities) => {
  try {
    // Check if local data should be used
    if (process.env.REACT_APP_USELOCALDATA === "true") {
      return require('./_local/_entities.json'); // Use local data
    }

    // Map over entities and create an array of promises for each API request
    const requests = entities.map(entity => {
      console.log('fetchEntity', entity);
      return client.get(apiURL, `?entitytype=${entity}`);
    });

    // Await all the promises (API requests) to resolve, and handle both success and failure
    const responses = await Promise.allSettled(requests);

    // Filter out failed requests and only keep successful ones
    const successfulResponses = responses
      .filter(result => result.status === 'fulfilled') // Keep only fulfilled promises
      .map(result => result.value.data); // Extract the data from fulfilled responses

    // Optionally flatten the data if needed
    const flattenedData = successfulResponses.flat(); // Uncomment if you expect an array of arrays

    console.log('fetchEntities flat', flattenedData);

    return flattenedData; // Or return flattenedData if you need a flat structure
  } catch (error) {
    console.log('fetchEntities', error);
    throw error; // Optional: rethrow the error for proper handling in the calling code
  }
});


export const fetchUsers = createAsyncThunk('filter/fetchUsers', async () => {
  try {
    if (process.env.REACT_APP_USELOCALDATA === "true") {
      return require('./_local/_users.json')
    }

    const response = await client.get(apiURL, '?type=users')
    console.log('fetchUsers', response.data)
    return response.data
  } catch (error) {
    console.log('fetchUsers', error)
  }
})

export const fetchQs = createAsyncThunk('filter/fetchQs', async () => {
  const response = await client.get(apiURL, '?type=queues')
  console.log('fetchQs', response.data)
  return response.data
})

export const fetchServiceMappings = createAsyncThunk('filter/fetchServiceMappings', async () => {
  try {
    if (process.env.REACT_APP_USELOCALDATA === "true") {
      return require('./_local/_servicemappings.json')
    }

    const response = await client.get(apiURL, '?type=services')
    console.log('fetchServiceMappings', response.data)
    return response.data
  } catch (error) {
    console.log('fetchServiceMappings', error)
  }
})

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchEntities.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchEntities.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched users to the array
        state.entities = action.payload
      })
      .addCase(fetchEntities.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

      .addCase(fetchUsers.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched users to the array
        state.users = action.payload
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

      .addCase(fetchQs.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchQs.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched qs to the array
        state.qs = action.payload
      })
      .addCase(fetchQs.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

      .addCase(fetchServiceMappings.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchServiceMappings.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched serviceMappings to the array
        state.serviceMappings = action.payload
      })
      .addCase(fetchServiceMappings.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

  }
})

export default filterSlice.reducer

export const selectAllEntities = (state) => state.filter.entities
export const selectAllUsers = (state) => state.filter.users
export const selectAllQs = (state) => state.filter.qs
export const selectAllServiceMappings = (state) => state.filter.serviceMappings

export const selectAllUniqueActions = (state) => {
  const allActions = state.filter.serviceMappings
    .flatMap(service => service.entities)
    .flatMap(entity => entity.actions);

  // Create a Set to get unique actions, then convert it back to an array
  const uniqueActions = [...new Set(allActions)];
  return uniqueActions;
};

export const selectAllUniqueEntityTypes = (state) => {
  const allEntityTypes = state.filter.serviceMappings
    .flatMap(service => service.entities)
    .map(entity => entity.name);

  // Create a Set to get unique entity types, then convert it back to an array
  const uniqueEntityTypes = [...new Set(allEntityTypes)];

  return uniqueEntityTypes;
};

export const selectUserById = (state, userId) =>
  state.filter.users.find((user) => user.id === userId)
