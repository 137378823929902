import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./assets/scss/style.scss";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import Loader from "./layouts/loader/Loader";

import store from "./store";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <React.Suspense fallback={<Loader />}>
        <App />
      </React.Suspense>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
