import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { client } from "../../api/client"

const apiURL = process.env.REACT_APP_QUERY_API_URL

const initialState = {
    logs: [],
    status: 'idle',
    error: null,
    lastkey: {},
    logParams: {},
}

const QueryParamaters = {
    DateFrom: '',
    DateTo: '',
    UserIds: [],
}

export const fetchLogs = createAsyncThunk(
    'logs/fetchLogs',
    async (paramaters) => {
        try {
            console.log('search', paramaters)           
            if (process.env.REACT_APP_USELOCALDATA === "true") {
                return require('./_local/_auditlogs.json')
            }
            const response = await client.post(apiURL, '?type=auditlogs', paramaters)
            console.log('logs/fetchLogs', response.data)
            
            return response.data    
        } catch (error) {
            console.log('logs/fetchLogs', error)
        }
    })

export const fetchMoreLogs = createAsyncThunk(
    'logs/fetchMoreLogs',
    async (paramaters) => {
        try {
            console.log('search', paramaters)
            if (process.env.REACT_APP_USELOCALDATA === "true") {
                return require('./_local/_auditlogs.json')
            }
            const response = await client.post(apiURL, '?type=auditlogs', paramaters)
            console.log('fetchMoreLogs', response.data)

            return response.data
        } catch (error) {
            console.log('logs/fetchMoreLogs', error)
        }

    })

const logsSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        clearData(state, action) {
            state.logs = []
            state.logParams = {}
        },
        setLogParams(state, action) {
            state.logParams = action.payload
            console.log('setLogParams', state.logParams)
        },
        updateLogParamsLastKey(state, action) {
            state.logParams.LastEvaluatedKey = action.payload
            console.log('updateLogParamsLastKey', state.logParams)
        }
    },
    extraReducers(builder) {
        builder
        //get logs
        .addCase(fetchLogs.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(fetchLogs.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.logParams.LastEvaluatedKey = action.payload.LastEvaluatedKey
            //set fetched logs
            state.logs = action.payload.Results
            console.log('fetchLogs.fulfilled', state.logs)
        })
        .addCase(fetchLogs.rejected, (state, action) => {
            state.status = 'failed'
            console.log('fetchLogs.rejected error: ', action.error.message)
            state.error = action.error.message
        })

        //get more logs using LastEvalutatedKey
        .addCase(fetchMoreLogs.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(fetchMoreLogs.fulfilled, (state, action) => {
            state.status = 'succeeded'
            console.log('fetchMoreLogs.fulfilled', action.payload)
            state.logParams.LastEvaluatedKey = action.payload.LastEvaluatedKey
            //set fetched logs
            state.logs = state.logs.concat(action.payload.Results)
            console.log('fetchMoreLogs.fulfilled', state.logs)
        })
        .addCase(fetchMoreLogs.rejected, (state, action) => {
            state.status = 'failed'
            console.log('fetchMoreLogs.rejected error: ', action.error.message)
            state.error = action.error.message
        })
    }
})

export const { clearData, setLogParams, updateLogParamsLastKey } = logsSlice.actions

export const selectLogs = (state) => state.logs.logs
export const selectLogParams = (state) => state.logs.logParams
export const selectLastEvaluatedKey = (state) => state.logs.lastkey

export default logsSlice.reducer