import { configureStore } from '@reduxjs/toolkit'
import authenticationReducer from "./features/auth/authenticationSlice"
import filterReducer from "./features/filters/filterSlice"
import logsSlice from './features/logs/logsSlice';

export default configureStore({
    reducer: {
        authentication: authenticationReducer,
        filter: filterReducer,
        logs: logsSlice,
    }
});
