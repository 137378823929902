import LogoLight from "../assets/images/logos/maintel-logo-light.png";
import LogoDark from "../assets/images/logos/maintel-logo-dark.png";
import { Link } from "react-router-dom";
import { useTheme } from "../hooks/useTheme";

const Logo = () => {
  const theme = useTheme();  
  return (
    // <Link to="/">
      <div className="text-center">
        {theme === "light-mode" ? 
          <img
          src={LogoLight}
          alt="maintel"
          width="90%"
          ></img>
        :
          <img
            src={LogoDark}
            alt="maintel"
            width="90%"
          ></img>
        }
      </div>
    // </Link>
  );
};

export default Logo;
